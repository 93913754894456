<template>
  <div class="create-tag modal">
    <div class="modal__container">
      <form
        class="create-tag"
        @submit.prevent
      >
        <div class="modal__top">
          <h1>Добавление тега</h1>
          <button
            class="modal__close-btn"
            @click="closeModal"
          >
            ✕
          </button>
        </div>

        <div class="modal__body">
          <div class="modal__input-container">
            <label
              class="modal__label"
              for="tagName"
            >
              Название Тега
              <input
                id="tagName"
                v-model="name"
                required
                class="modal__unput required name"
                type="text"
                placeholder="Название тега"
                maxlength="50"
                minlength="3"
              >
            </label>
            <label
              class="modal__label"
            >
              Приложения
              <multiselect
                v-model="selectApps"
                class="modal__select--single multiselect"
                label="name"
                track-by="id"
                placeholder="Приложения"
                :options="appsList.data"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
              />
            </label>
            <label
              class="modal__label"
            >
              Пуши
              <multiselect
                v-model="selectNotifies"
                class="modal__select--single multiselect"
                label="name"
                track-by="id"
                placeholder="Пуши"
                :options="notifyList.data"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
              />
            </label>
          </div>
          <button
            class="modal__create-btn"
            type="button"
            @click="editTag"
          >
            Редактировать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: 'EditTags',
  components: { Multiselect },
  props: ['tagItem', 'appsList', 'notifyList'],
  // props: {
  //   tagItem: {
  //     type: [Object, Array],
  //     required: true,
  //   },
  //   appsList: {
  //     type: [Array,Object],
  //     required: true,
  //   },
  //   notifyList: {
  //     type: [Array,Object],
  //     required: true,
  //   },
  // },
  data() {
    return {
      name: this.tagItem.name,
      selectApps: this.tagItem.applications,
      selectNotifies: this.tagItem.notify,
    };
  },
  methods: {
    getIds (arr) {
      return arr.map(app => app.id);
    },
    editTag() {
      this.$emit('edit', {
        id: this.tagItem.id,
        name: this.name,
        notify: this.getIds(this.selectNotifies),
        applications: this.getIds(this.selectApps),
      });
    },
    closeModal() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.create-tag {
  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    background: #161616;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal {
    &__container {
      background: #242424;
      padding: 20px;
      border-radius: 10px;
      min-width: 600px;
      min-height: 200px;
      max-width: 800px;
    }

    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        margin: 0
      }
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px
    }

    &__input-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;


      label {
        gap: 8px;

        &:has(.name) {
          width: 100%
        }
      }

      .required {
        margin-top: 0;
      }
    }

    &__close-btn {
      background: transparent;
      color: white;
      border: none;
      font-size: 24px;
      padding: 0;
    }

    &__create-btn {
      width: 200px;
      height: 40px;
      border-radius: 8px;
      background: #ff2a00;
      color: #fff;
      font-size: 16px;
      border: none;
      font-family: HalvarR;
    }
  }
}
</style>

<style lang="scss">
.create-tag {
  .multiselect__select {
    right: 0;
    top: 10px
  }
}
</style>