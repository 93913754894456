<template>
  <div>
    <main-header :login="true" />
    <div class="main-block tags">
      <div>
        <div class="top__block">
          <h1>Теги</h1>
          <button
            class="add-push__btn"
            @click="isAddTag = !isAddTag"
          >
            <img
              src="../assets/images/plus.svg"
              alt=""
            >
          </button>
        </div>
        <div class="scroll_container">
          <div
            id="sort-bar"
            style="margin-top: 0"
          >
            <multiselect
              v-model="selectTags"
              class="modal__select--single multiselect"
              label="name"
              track-by="name"
              placeholder="Теги"
              :options="filterParams"
              :multiple="true"
              :searchable="true"
              :allow-empty="true"
              @input="filterTags(selectTags)"
            />
          </div>
          <div class="table__info">
            <div class="count__list">
              <label class="modal__label">Показывать записей:</label>
              <multiselect
                v-model="showEntries"
                :options="listOptions"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                placeholder="25"
                class="count__multiselect"
                @input="limitPage(value)"
              />
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Тег</th>
                <th>Приложения</th>
                <th>Пуши</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in tagsList"
                :key="item.id"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <div
                    v-if="item.applications.length > 0"
                    style="cursor: pointer"
                    @click="showApps(item.applications)"
                  >
                    <img
                      src="../assets/images/info.svg"
                      alt="icon"
                    >
                  </div>
                  <div v-else>
                    -
                  </div>
                </td>
                <td>
                  <div
                    v-if="item.notify.length > 0"
                    style="cursor: pointer"
                    @click="showNotify(item.notify)"
                  >
                    <img
                      src="../assets/images/info.svg"
                      alt="icon"
                    >
                  </div>
                  <div v-else>
                    -
                  </div>
                </td>
                <td>
                  <button
                    class="see_btn"
                    @click="editTag(item)"
                  >
                    <div class="icon edit" />
                  </button>
                  <button
                    class="delete_btn delete"
                    @click="isDeleteTag(item.id)"
                  >
                    <img
                      src="../assets/images/delete_outline.svg"
                      alt="icon"
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="showPagination">
          <ul class="pagination">
            <li v-if="showPreviousButton">
              <a @click="paginationPage(1)">First</a>
            </li>
            <li v-if="showPreviousButton">
              <a @click="paginationPage(currentPage - 1)">
                Prev
              </a>
            </li>
            <li
              v-for="page in visiblePages"
              :key="page"
              :class="{ active: currentPage === page }"
            >
              <a @click="paginationPage(page)">{{ page }}</a>
            </li>
            <li v-if="showNextButton">
              <a @click="paginationPage(currentPage + 1)">
                Next
              </a>
            </li>
            <li v-if="showNextButton">
              <a @click="paginationPage(tagsList.pages)">Last</a>
            </li>
          </ul>
          <div>
            <p
              v-for="item in paginatedData"
              :key="item"
            >
              {{ item }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <add-tags
      v-if="isAddTag"
      :apps-list="appsList"
      :notify-list="notifyList"
      @create="createTag"
      @close="isAddTag = !isAddTag"
    />
    <edit-tags
      v-if="isEditTag"
      :tag-item="tagItem"
      :apps-list="appsList"
      :notify-list="notifyList"
      @edit="sendEditTag"
      @close="isEditTag = !isEditTag"
    />
    <see-params
      v-if="isShowApps"
      :items-arr="appsArr"
      :title="'Приложения'"
      @close="isShowApps = false"
    />
    <see-params
      v-if="isShowNotify"
      :items-arr="notifyArr"
      :title="'Приложения'"
      @close="isShowNotify = false"
    />
    <error-modal
      v-if="isError"
      :error="error"
      @close="isError = false"
    />
    <div
      v-if="openModalDelete"
      class="modal__main"
    >
      <div class="wrapper_modal__window">
        <div class="top__block">
          <h1>Вы уверены что хотите удалить пуш безвозвратно?</h1>
        </div>
        <div
          class="btns__block"
          style="gap: 20px"
        >
          <button
            class="next-step__btn"
            @click="deleteTag(itemId)"
          >
            Да
          </button>
          <button
            class="next-step__btn"
            style="background: transparent;border: 1px solid #161616"
            @click="openModalDelete = false"
          >
            Отмена
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import MainHeader from "@/components/header/header.vue";
import { HTTP } from "@/../axios-config";
import AddTags from "@/components/tags/addTag.vue";
import Multiselect from "vue-multiselect";
import EditTags from "@/components/tags/editTag.vue";
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
import SeeParams from "@/components/tags/seeParams.vue";
import ErrorModal from "@/components/elements/errorModal.vue";

export default {
  name: "TagsPage",
  components: {
    ErrorModal,
    SeeParams,
    filtersMultiselect,
    EditTags,
    Multiselect,
    AddTags,
    MainHeader,
  },
  data() {
    return {
      tagItem: [],
      paginationData: [],
      showPagination: false,
      showEntries: 10,
      currentPage: 1,
      maxVisiblePages: 5,
      listOptions: ['10', '25', '50'],
      isAddTag: false,
      isEditTag: false,
      tagsList: [],
      appsList: [],
      notifyList: [],
      selectTags: [],
      filterTagsId: '',
      filterParams: [],
      isShowApps: false,
      isShowNotify: false,
      appsArr: [],
      notifyArr: [],
      error: [],
      isError: false,
      openModalDelete: false,
      itemId: null,
    };
  },
  mounted() {
    this.fetchTags();
    this.fetchApps();
    this.fetchNotifies();
  },
  methods: {
    showApps(item) {
      this.appsArr = item;
      this.isShowApps = !this.isShowApps;
    },
    showNotify(item) {
      this.notifyArr = item;
      this.isShowNotify = !this.isShowNotify;
    },
    filterTags(params) {
      this.filterTagsId = params.map(app => app.id).join(',');
      this.fetchTags();
    },
    paginationPage(page) {
      this.currentPage = page;
      this.fetchTags();
    },
    limitPage(limit) {
      this.currentPage = 1;
      this.fetchTags();
    },
    editTag(item) {
      this.tagItem = item;
      this.isEditTag = true;
    },
    sendEditTag(data) {
      HTTP.put('tags/update', data)
        .then((response) => {
          this.isEditTag = false;
          this.fetchTags();

        })
        .catch((error) => {
          console.log('error', error);
        })
    },
    isDeleteTag(id) {
      this.openModalDelete = true;
      this.itemId = id;
    },
    deleteTag(id) {
      HTTP.delete(`/tags/delete/${id}`)
        .then((response) => {
          this.openModalDelete = false;
          this.fetchTags();
        })
        .catch((error) => {
          console.log('error', error);
        })
    },
    createTag(data) {
      HTTP.post('/tags/add', data)
        .then((response) => {
          this.isAddTag = false;
          this.fetchTags();
        })
        .catch((error) => {
          console.log('error', error);
          this.error = error.response.data.data.errors;
          this.isError = true;
        })
    },
    fetchTags() {
      let filterParam = '';
      if (this.filterTagsId && this.filterTagsId.length > 0) {
        filterParam = `&filter[id]=${this.filterTagsId}`
      }
      if (filterParam.length > 1) {
        this.currentPage = 1;
      }
      HTTP.get(`/tags/list?page=${this.currentPage}&limit=${this.showEntries}${filterParam}`)
        .then(response => {
          let data = response.data;
          this.tagsList = data.data;
          this.showPagination = (this.tagsList.pages > 1) ? true : false;
          this.filterParams = data.filter;
        })
        .catch((error) => {
          console.log('error', error);
        })
    },
    fetchApps() {
      HTTP.get('/applications/all')
        .then((response) => {
          this.appsList = response;
        })
        .catch((error) => {
          console.log('error', error);
        })
    },
    fetchNotifies() {
      HTTP.get('/notify/list/all')
        .then((response) => {
          this.notifyList = response;
        })
        .catch((error) => {
          console.log('error', error);
        })
    },
  },
  computed: {
    visiblePages() {
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(this.maxVisiblePages / 2)
      );
      const endPage = Math.min(
        startPage + this.maxVisiblePages - 1,
        this.tagsList.pages
      );

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
    showPreviousButton() {
      return this.currentPage > 1;
    },
    showNextButton() {
      return this.currentPage < this.tagsList.pages;
    },
    paginatedData() {
      let startIndex =
        (this.currentPage - 1) * this.showEntries;
      const endIndex = startIndex + this.showEntries;
      return this.paginationData.slice(startIndex, endIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  width: 100vw !important;
  height: 100vh;
  display: block;
  align-items: flex-start;
  justify-content: center;
  padding: 120px 80px;
  box-sizing: border-box;
}

.count__list {
  label {
    width: max-content;
    margin-left: 0;
    margin-right: 5px;
  }
}
</style>

<style lang="scss">
.tags {
  .multiselect__select {
    min-width: 10px !important;
    width: 10px!important;
  }

  #sort-bar .multiselect__select {
    right: 28px;
    top: 28px;
  }
}
</style>