<template>
  <div>
    <div class="wrapper_modal add-push edit">
      <div class="modal__menu">
        <div
          v-for="itemMenu in itemsMenu"
          :key="itemMenu.id"
          :class="{
            'active-tab': itemMenu.id === step,
            'hidden': itemMenu.type === 'hidden' && newPush.device !== 'android' || itemMenu.type === newPush.type
          }"
          class="modal__menu--item"
          @click="ChangeActiveTab(itemMenu.id)"
        >
          {{ itemMenu.name }}
          <img src="../../assets/images/arrow_menu.svg">
        </div>
      </div>
      <div class="wrapper_modal__window">
        <div class="top__block">
          <h1>Редактирование</h1>
          <div
            class="close__btn"
            @click="backToPushList"
          >
            X
          </div>
        </div>
        <div
          v-if="step === 2"
          class="modal__second-step"
        >
          <div class="input__item">
            <label class="modal__label">Название*</label>
            <input
              v-model="newPush.name"
              type="text"
              class="modal__input required"
              maxlength="50"
              minlength="3"
            >
          </div>
          <div class="input__item">
            <label class="modal__label">Теги*</label>
            <multiselect
              v-model="newPush.tags"
              class="modal__select--single modal__input required"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите приложение"
              :options="tagsList"
              :allow-empty="true"
            />
          </div>
          <div class="input__item">
            <label class="modal__label">Приложение*</label>
            <multiselect
              v-model="newPush.applications"
              class="modal__select--single"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите приложение"
              :options="appsList"
              :allow-empty="true"
              @input="appIncludeType(newPush.applications)"
            />
          </div>
          <div
            class="js-app-input input__item"
          >
            <label class="modal__label">Исключить из рассылки</label>
            <multiselect
              v-model="appIdException"
              class="modal__select--single"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите приложение"
              :options="appsList"
              :allow-empty="true"
            />
          </div>
        </div>
        <div v-if="step === 3">
          <div style="margin-top: 10px">
            <table class="table">
              <thead>
                <tr>
                  <th style="text-align: center">
                    Users
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in newPush.externals_users"
                  :key="item.id"
                >
                  <td>{{ item }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="step === 4"
          class="modal__second-step"
        >
          <p class="modal__intro">
            Если вы ничего не выбираете то пуш будет отправляться всем вне
            зависимости от тегов
          </p>
          <div
            v-for="(item, itemKey) in tags"
            :key="item.id"
            class="input__item"
          >
            <label class="modal__label tags">{{ itemKey }}</label>
            <multiselect
              v-model="choiseTags[itemKey]"
              class="modal__select--single required"
              :multiple="true"
              placeholder="Выберите"
              :options="item"
              :allow-empty="true"
            />
          </div>
        </div>
        <div
          v-if="step === 5"
          class="modal__second-step"
        >
          <div class="input__item">
            <label class="modal__label">ГЕО*</label>
            <multiselect
              v-model="tempGeo"
              class="modal__select--single required"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите страну"
              :options="geos"
              :allow-empty="true"
              @input="geoIncludeType(tempGeo)"
            />
          </div>
          <div
            v-if="excludeGeo"
            class="js-geo-input input__item"
          >
            <label class="modal__label">Исключить из рассылки</label>
            <multiselect
              v-model="newPush.geoExcept"
              class="modal__select--single"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите страну"
              :options="geoExclude"
              :allow-empty="true"
            />
          </div>
        </div>
        <div
          v-if="step === 6"
          class="modal__second-step"
        >
          <p class="modal__intro">
            Не забудьте настроить постбеки на события "Регистрация/Депозит",
            если вы используете опцию учета свершения/не свершения событий
            "Регистрация/Депозит"
          </p>
          <div class="input__item">
            <label class="modal__label">Совершенное событие*</label>
            <multiselect
              v-model="statementVal"
              class="modal__select--single required"
              deselect-label="Can't remove this value"
              track-by="name"
              label="name"
              placeholder="Выберите событие"
              :options="statementList"
              :searchable="false"
              :allow-empty="false"
            />
          </div>
          <div class="input__item">
            <label class="modal__label">Несовершенное событие*</label>
            <multiselect
              v-model="exceptVal"
              class="modal__select--single required"
              deselect-label="Can't remove this value"
              track-by="name"
              label="name"
              placeholder="Выберите событие"
              :options="statementList"
              :searchable="false"
              :allow-empty="false"
            />
          </div>
        </div>
        <div
          v-if="step === 7"
          class="modal__second-step"
        >
          <p class="modal__intro">
            Если вы ничего не выбираете то пуш будет отправляться всем вне
            зависимости от оффера
          </p>
          <div class="input__item">
            <label class="modal__label">Офферы</label>
            <multiselect
              v-model="newPush.offers"
              class="modal__select--single required"
              :multiple="true"
              placeholder="Выберите оффер"
              :options="offersList"
              :allow-empty="true"
            />
          </div>
        </div>
        <div
          v-if="step === 8"
          class="modal__second-step"
        >
          <div class="input__item">
            <div class="input__item">
              <label
                v-if="showDate"
                class="modal__label"
              >
                Выбрать время отправки пуша
              </label>
              <label
                v-if="showDelay"
                class="modal__label"
              >
                Через какое время отправить пуш
              </label>
              <label
                v-if="showMoment"
                class="modal__label max-width"
              >
                Если пропустить этот пункт, пуш отправиться сразу после
                создания<br><br>
                Выбрать время и дату отправки
              </label>
              <p class="error" />
              <div
                :class="{ 'is-block': showMoment }"
                class="modal__flex input__item"
              >
                <div
                  v-if="!showDate"
                  class="modal__input--container"
                >
                  <label class="modal__label time">Время</label>
                  <div
                    style="width: 90px"
                    class="modal__input delay-see required"
                  >
                    {{ newPush.DelaySee }}
                  </div>
                </div>
                <div
                  class="input__item"
                  style="display: flex; align-items: baseline;flex-direction: column"
                >
                  <div
                    v-if="showDate"
                    class="modal__input--container"
                    style="margin-bottom: -10px"
                  >
                    <p class="modal__label">
                      Постоянная отправка
                    </p>
                    <label class="custom-checkbox">
                      <input
                        v-model="newPush.infinity_send"
                        type="checkbox"
                        value="1"
                        disabled
                      >
                      <span />
                    </label>
                  </div>
                  <div
                    v-if="showDate && newPush.infinity_send === 0"
                    class="modal__input--container"
                  >
                    <label class="modal__label">Кол-во дней*</label>
                    <input
                      v-model="newPush.days_send"
                      :disabled="true"
                      type="text"
                      class="modal__input day"
                      maxlength="2"
                      placeholder="1"
                    >
                  </div>
                  <div
                    v-if="!showDelay"
                    class="modal__input--container"
                  >
                    <p class="modal__label">
                      Отправить по локальному времени
                    </p>
                    <label class="custom-checkbox">
                      <input
                        v-model="newPush.local_time"
                        type="checkbox"
                        value="1"
                        disabled
                      >
                      <span />
                    </label>
                  </div>
                </div>
                <div
                  v-if="showMoment"
                  class="input__item"
                  style="display: flex; position: relative"
                >
                  <div
                    class="input__item"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 300px;
                      height: 300px;
                      z-index: 1111;
                    "
                  />
                  <v-date-picker
                    v-model="date"
                    max="today"
                    :min-date="date"
                    :value="date"
                    color="red"
                    is-dark
                  />
                </div>
              </div>
            </div>
            <div
              v-if="showDate"
              class="repeat__day"
              style="width: 100%; min-width: 250px; overflow: scroll"
            >
              <label class="modal__label">Время отправки</label>
              <div
                v-for="item in itemsDayInput"
                :key="item.id"
                class="checkbox__module"
              >
                <label class="custom-checkbox">
                  <p>{{ item.day }}</p>
                  <input
                    v-model="item.checked"
                    :disabled="true"
                    type="checkbox"
                    :value="item.id"
                    @click="addNewTimeInputForEverydayPush"
                  >
                  <span />
                </label>
                <div
                  class="time__inputs--block"
                  :class="{ hide: !item.checked }"
                >
                  <div
                    v-for="time in item.time"
                    :key="time.id"
                    class="time__inputs--item"
                  >
                    <input
                      v-model="time.value"
                      :disabled="true"
                      type="time"
                      class="input__time"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="step === 9"
          :class="{ 'not-everyday': !showDate }"
          class="modal__second-step"
        >
          <div>
            <b-card no-body>
              <b-tabs card>
                <!-- Render Tabs, supply a unique `key` to each tab -->
                <b-tab
                  v-for="item in tabs"
                  :key="'dyn-tab-' + item.id"
                  :title="'Item ' + item.id"
                >
                  <div
                    v-if="showDelay"
                    class="modal__input--container delay__block"
                  >
                    <label class="modal__label time">Время Задержки</label>
                    <div style="position: relative">
                      <input
                        v-model="item.data.days"
                        type="text"
                        class="modal__input delay required"
                        placeholder="day"
                      >
                    </div>
                    <div style="position: relative">
                      <input
                        v-model="item.data.hours"
                        type="text"
                        class="modal__input delay required"
                        :class="{'valid-error': showErrorHour}"
                        placeholder="hour"
                        @input="checkHour(item.data)"
                      >
                      <span
                        v-if="showErrorHour"
                        class="error-message"
                      >
                        Час не может быть больше 23
                      </span>
                    </div>
                    <div style="position: relative">
                      <input
                        v-model="item.data.minutes"
                        type="text"
                        class="modal__input delay required"
                        :class="{'valid-error': showErrorMinutes}"
                        placeholder="minutes"
                        @input="checkMinutes(item.data)"
                      >
                      <span
                        v-if="showErrorMinutes"
                        class="error-message"
                      >
                        Минуты не могут быть больше 59
                      </span>
                    </div>
                  </div>
                  <div class="push__data">
                    <div class="modal__block">
                      <div class="data__block fixed">
                        <div class="grid__first-el">
                          <label class="modal__label">URL иконки*</label>
                          <input
                            v-model="item.data.ico_url"
                            type="text"
                            class="modal__input day required"
                            placeholder="https://example.com/fjfk"
                          >
                        </div>
                        <div class="grid__second-el">
                          <label class="modal__label">URL картинки*</label>
                          <input
                            v-model="item.data.pic_url"
                            type="text"
                            class="modal__input day required"
                            placeholder="https://example.com/fjfk"
                          >
                        </div>
                        <div class="grid__last-el">
                          <label class="modal__label">
                            Ссылка при открытии*
                          </label>
                          <input
                            v-model="item.data.url"
                            type="text"
                            class="modal__input day required"
                            placeholder="https://example.com/fjfk"
                          >
                        </div>
                      </div>
                      <div
                        v-for="langs in item.data.lang"
                        :key="langs.id"
                        class="data__block js-add-lang-block"
                        style="position: relative"
                      >
                        <button
                          v-if="langs.value.code !== 'en'"
                          class="delete__lang"
                          @click="deleteLang(langs, item.data.lang)"
                        >
                          <img src="../../assets/images/delete_outline-lang.svg">
                        </button>
                        <div
                          v-if="langs.key === 'en'"
                          style="
                            position: absolute;
                            top: 52px;
                            left: 0px;
                            width: 310px;
                            z-index: 1;
                            height: 40px;
                            background: rgba(255, 255, 255, 0.1);
                            border-radius: 8px;
                            cursor: not-allowed;
                          "
                        />
                        <div>
                          <label class="modal__label title">
                            Язык перевода*(сейчас {{ langs.key }})
                          </label>
                          <multiselect
                            v-model="langs.value"
                            class="modal__select--single"
                            :multiple="false"
                            track-by="name"
                            label="name"
                            placeholder="Выберите Язык"
                            :options="languages"
                            :allow-empty="true"
                          />
                        </div>
                        <div>
                          <label class="modal__label title">Заголовок*</label>
                          <input
                            v-model="langs.title"
                            type="text"
                            class="modal__input day required"
                            placeholder="Apps title"
                          >
                        </div>
                        <div class="grid__last-el">
                          <label class="modal__label title">Сообщение*</label>
                          <input
                            v-model="langs.text"
                            type="text"
                            class="modal__input text required"
                            style="height: 70px"
                          >
                        </div>
                      </div>
                      <div class="lang-btn__block">
                        <div
                          class="add__lang-btn"
                          @click="addNewLangBlock(item.data.lang)"
                        >
                          Добавить перевод
                          <img src="../../assets/images/add_item.svg">
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-button
                    v-if="showDate"
                    disabled
                    size="sm"
                    variant="danger"
                    class="float-right btn-close__push"
                    @click="closeTab(item.id)"
                  >
                    Close tab
                  </b-button>
                </b-tab>
                <!-- New Tab Button (Using tabs-end slot) -->
                <template #tabs-end>
                  <b-nav-item
                    v-if="showDate"
                    disabled
                    role="presentation"
                    href="#"
                    @click.prevent="newTabClick()"
                  >
                    <b>+</b>
                  </b-nav-item>
                </template>
                <!-- Render this if no tabs -->
              </b-tabs>
            </b-card>
          </div>
        </div>
        <div class="btns__block">
          <button
            class="next-step__btn"
            @click="sendPush"
          >
            Отредактировать пуш
          </button>
        </div>
      </div>
      <div
        v-if="isErrorModal"
        class="modal__main"
      >
        <div class="wrapper_modal__window">
          <div
            class="top__block"
            style="text-align: center"
          >
            <h1>{{ errorModalText }}</h1>
          </div>
          <div class="btns__block">
            <button
              class="next-step__btn"
              @click="isErrorModal = false"
            >
              Отлично
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from 'vue-multiselect';
import { HTTP } from "@/../axios-config";

export default {
  name: "EditStepPushes",
  components: {
    Multiselect,
  },
  props: ["type", "title", "itemChange", "geos", "languagesList", "appsList", "tagsList"],
  data() {
    return {
      Delay: new Date(),
      date: new Date(),
      newPush: this.itemChange,
      choiseTags: {},
      step: 2,
      appIncType: 0,
      geoIncType: 0,
      newDate: '',
      excludeApp: false,
      excludeGeo: false,
      showDate: false,
      showMoment: false,
      showDelay: false,
      localTime: false,
      isErrorModal: false,
      geoExclude: this.geos,
      statementVal: null,
      exceptVal: null,
      errorModalText: '',
      appExclude: this.appsList,
      appIdException: [],
      tags: [],
      tempGeo: [],
      languages: [],
      offersList: ['Всем без офферов'],
      dates: {
        weekdays: [1,2,3,4,5,6,7],
      },
      itemsMenu : [
        {
          id: 2,
          name: 'Выбор прилы',
          type: '',
        },
        {
          id: 3,
          name: 'External ID',
          type: 'hidden',
        },
        {
          id: 4,
          name: 'Теги',
          type: 'hidden',
        },
        {
          id: 5,
          name: 'ГЕО',
          type: '',
        },
        {
          id: 6,
          name: 'Постбек',
          type: '',
        },
        {
          id: 7,
          name: 'Выбор оффера',
          type: '',
        },
        {
          id: 8,
          name: 'День и время',
          type: 1,
        },
        {
          id: 9,
          name: 'URL ссылки',
          type: '',
        },
      ],
      statementList: [
        {
          name: "None",
          value: 0,
        },
        {
          name: "install",
          value: 1,
        },
        {
          name: "Registration",
          value: 2,
        },
        {
          name: "Deposit",
          value: 3,
        },
      ],
      tabs: [],
      itemsDayInput: [
        {
          checked: false,
          day: "Вс",
          id: 0,
          time: [],
        },
        {
          day: "Пн",
          checked: false,
          id: 1,
          time: [],
        },
        {
          day: "Вт",
          checked: false,
          id: 2,
          time: [],
        },
        {
          checked: false,
          day: "Ср",
          id: 3,
          time: [],
        },
        {
          checked: false,
          day: "Чт",
          id: 4,
          time: [],
        },
        {
          checked: false,
          day: "Пт",
          id: 5,
          time: [],
        },
        {
          checked: false,
          day: "Сб",
          id: 6,
          time: [],
        },
      ],
      showErrorHour: false,
      showErrorMinutes: false,
    };
  },
  methods: {
    getIds (arr) {
      return arr.map(app => app.id);
    },
    convertMinutes(totalMinutes) {
      const minutesInDay = 24 * 60;
      const minutesInHour = 60;

      const days = Math.floor(totalMinutes / minutesInDay);
      const remainingMinutesAfterDays = totalMinutes % minutesInDay;
      const hours = Math.floor(remainingMinutesAfterDays / minutesInHour);
      const minutes = remainingMinutesAfterDays % minutesInHour;

      return {
        days: days,
        hours: hours,
        minutes: minutes
      };
    },
    checkHour(item) {
      const hour = parseInt(item.hour, 10);
      this.showErrorHour = (hour > 23) ?? true;
    },
    checkMinutes(item) {
      const minutes = parseInt(item.minutes, 10);
      this.showErrorMinutes = (minutes > 59) ?? true;
    },
    deleteLang(item, array) {
      if (item.key == 'en') {
        return ;
      }
      for (let i = 1; i < array.length; i++) {
        if (item.key === array[i].key) {
          array.splice(i, 1);
          return ;
        }
      }
    },
    ChangeActiveTab(id) {
      this.step = id;
    },
    backToPushList() {
      this.$emit('close', {
        stepPush: false,
      })
    },
    getTags() {
      console.log('iii', this.itemChange)
      const vm = this;
      HTTP.get(`/tags/`)
          .then(function (response) {
            vm.tags = response.data.data;
          })
          .catch(function (error) {
            console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

          });
    },
    getOffers() {
      const vm = this;

      HTTP.get(`/offers/1000000/1`)
          .then(function (response) {
            let offersList = response.data.data.offers;
            for(let i = 0; i < offersList.length; i++) {
              vm.offersList.push(offersList[i].name);
            }
          })
          .catch(function (error) {
            console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

          });
    },
    getLanguages() {
      const vm = this;

      HTTP.get('/language/')
          .then(function (response) {
            vm.languages = response.data.data;
          })
          .catch(function (error) {
            console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

          });
    },
    chooseTags() {
      let tagsItem = this.newPush.tags;

      if (Object.keys(tagsItem).length > 0) {
        this.choiseTags = this.newPush.tags;
      }
    },
    chooseGeosList() {
      this.geos.map((el, i) => {
        let geoCode = el;

        if ($.inArray(geoCode.code, this.newPush.geo) !== -1) {
          this.tempGeo.push(geoCode);
        }
      });
    },
    chooseStatementAndExceptVal() {
      this.statementVal = this.caseStatementExcept(this.newPush.statement);
      this.exceptVal = this.caseStatementExcept(this.newPush.except);
    },
    chooseTextForDateTimeBlock() {
      switch(this.newPush.type) {
        case 1:
          this.showDelay = true;
          this.showDate = true;
          break;
        case 2:
          this.showDate = true;
          break;
        case 3:
          this.showMoment = true;
          break;
        default:
          break;
      }
    },
    chooseParamForDateTimeBlock() {
      this.Delay = new Date(`14 Jun 2017 ${this.newPush.delay}:00`);
      this.newPush.DelaySee = this.newPush.delay;
      if (this.newPush.date) {
        let dates = this.newPush.date;
        let splitDataArr = dates.split('-');
        this.date = new Date(splitDataArr[0], splitDataArr[1]-1, splitDataArr[2]);  // 2009-11-10
      }

      if (this.typePush == 2) {
        this.newDate = this.newPush.day_of_the_week;
      } else {
        this.newDate = new Date(this.newPush.date);
      }

      for (let i = 0; i < this.itemsDayInput.length; i++) {
        if (this.newPush.day_of_the_week[i].length > 0) {
          this.itemsDayInput[i].checked = true;
          let temp = this.newPush.day_of_the_week[i];
          for (let j = 0; j < temp.length; j++) {
            if (temp[j].length > 0) {
              this.itemsDayInput[i].time.push({value: temp[j]})
            }
          }
        }
      }
    },
    chooseTabs() {
      let j = 1;
      for (let i = 0; i < this.newPush.push.length; i++) {
          this.tabs.push({
            id: j++,
            data: {
              ico_url: this.newPush.push[i].ico_url,
              pic_url: this.newPush.push[i].pic_url,
              url: this.newPush.push[i].url,
              lang: [],
            }
          })
        Object.assign(this.tabs[i].data, this.convertMinutes(this.newPush.push[i].delay))

        for (let j = 0; j < Object.keys(this.newPush.push[i].lang).length; j++) {
          let cnt = Object.keys(this.newPush.push[i].lang)[j];
          let langVal = this.newPush.push[i].lang[cnt];
          this.tabs[i].data.lang.push(
            {
              value: '',
              title: langVal.title,
              text: langVal.text,
              key: langVal.key,
            }
          );

        }
      }
    },
    chooseEmptyOffers() {
      let offers = this.newPush.offers;

      if (offers[0] === '') {
        offers.shift();
      }
    },
    chooseAppsForGroups() {
      if (this.newPush.applications) {
        let appIds = this.newPush.applications;
        let groupNameArr = [
          "Dating",
          "Gambling",
          "Betting",
          "all",
        ];

        for (let i = 0; i < appIds.length; i++) {
          if (groupNameArr.indexOf(appIds[i].id) !== -1) {
            this.excludeApp = true;
          }
        }
      }
    },
    getValuesForEdit() {
      this.chooseAppsForGroups();

      for (let i = 0; i < this.newPush.appIdExcept.length; i++) {
        this.appIdException.push(this.newPush.appIdExcept[i]);
      }
      this.chooseEmptyOffers();
      this.chooseTags();
      this.chooseGeosList();
      this.chooseStatementAndExceptVal();
      this.chooseTextForDateTimeBlock();
      this.chooseParamForDateTimeBlock();
      this.chooseTabs();
    },
    caseStatementExcept(type) {
      let arr = [];

      switch (type) {
        case 0:
          arr = {
            name: "None",
            value: 0,
          };
          break;
        case 1:
          arr = {
            name: "Install",
            value: 1,
          };
          break;
        case 2:
          arr = {
            name: "Registration",
            value: 2,
          };
          break;
        case 3:
          arr = {
            name: "Deposit",
            value: 3,
          };
          break;
        default:
      }
      return arr;
    },
    appIncludeType(appId) {
      for (let i = 0; i < appId.length; i++) {
        if (appId[i].id == "all"
            || appId[i].id == "Gambling"
            || appId[i].id == "Betting"
            || appId[i].id == "Dating"
        ) {
          this.appIncType = 1;
          this.excludeApp = true;
        } else {
          this.excludeApp = false;
        }
      }
    },
    geoIncludeType(geoId) {
      for (let i = 0; i < geoId.length; i++) {
        if (geoId[i].code == "all") {
          this.geoIncType = 1;
          this.excludeGeo = true;
        } else {
          this.excludeGeo = false;
        }
      }
    },
    addNewLangBlock(data) {
      data.push({
        value: '',
        text: '',
        code: '',
      });
    },
    addNewTimeInputForEverydayPush({ target }) {
      let parentBlock = $(target).parents('.checkbox__module');
      parentBlock.find('.time__inputs--block').toggleClass('hide');
    },
    newTabClick() {
      let tabCounter = this.tabs.length + 1;
      this.tabs.push({
        id: tabCounter++,
        data: {
          ico_url: '',
          pic_url: '',
          url: '',
          lang: [
            {
              value: '',
              title: '',
              text: '',
            }
          ]
        }
      });
    },
    closeTab(x) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].id === x) {
          this.tabs.splice(i, 1)
        }
      }
    },
    getContentToPush() {
      let pushes = [];

      pushes = this.tabs;
      let tempPushes = [];

        for (let i = 0; i < pushes.length; i++) {
          let pus = pushes[i].data;

          pus.name = this.newPush.name;
          pus.id = this.newPush.push[i].id;
          for (let j = 0; j < pus.lang.length; j++) {
            let langs = pus.lang[j];

            if (langs.value) {
              langs.key = langs.value.code;
              delete langs['value'];
            }
          }
          let day = (pus.days) ? parseInt(pus.days) : 0;
          let hours = (pus.hours)
            ? parseInt(day) * 24  + parseInt(pus.hours)
            : parseInt(day) * 24;

          let minutes = (pus.minutes) ? parseInt(pus.minutes) : 0;

          let formatMinutes = (hours > 0)
            ? parseInt(hours * 60  + minutes)
            : parseInt(minutes);

          let delayTime = formatMinutes;

          pus.delay = delayTime;
          tempPushes.push(pus);
        }
      return tempPushes;
    },
    getTimeForEverydayPush() {
      let timeInputs = this.itemsDayInput;
      let finalArr = [];

      for (let i = 0;i < timeInputs.length;i++) {
        let timeArr = timeInputs[i].time;
        let tempArr = [];

        for (let j = 0; j < timeArr.length;j++) {
          if (timeArr[j].value) {
            tempArr.push(timeArr[j].value);
          }
        }
        finalArr[i] = tempArr;
        tempArr = [];
      }

      return finalArr;
    },
    getAppsForSendPush() {
      let apps = this.newPush.applications;
      let appIndexes = [];
      let groupArr = ['Gambling', 'Betting', 'Dating']
      for (let appCount = 0; appCount < apps.length; appCount++) {
        if (groupArr.includes(apps[appCount].name)) {
          apps[appCount].id = apps[appCount].name;
          console.log('sss', apps[appCount].id);
        }
        if (apps[appCount].id !== null) {
          appIndexes.push(apps[appCount].id);
        }
      }
      return appIndexes;
    },
    getIncludeAppForSendPush() {
      let appsExcept = this.appIdException;
      let appIncludeType = (appsExcept) ? [] : 0;

      if (appsExcept) {
        for(let cnt = 0; cnt < appsExcept.length; cnt++) {
          appIncludeType.push(appsExcept[cnt].id);
        }
      }
      return appIncludeType;
    },
    getGeosForSendPush() {
      let geos = this.tempGeo;
      let geosExcept = this.newPush.geoExcept;

      let geoCodes = [];

      if (!this.newPush.geoExcept) {
        for (let geoCount = 0; geoCount < geos.length; geoCount++) {
          geoCodes.push(geos[geoCount].code);
        }
      } else {
        for (let geoCount = 0; geoCount < geosExcept.length; geoCount++) {
          geoCodes.push(geosExcept[geoCount].code);
        }
      }

      return geoCodes;

    },
    addParamForTemplate(template) {
      if (this.newPush.Type == 2) {
        template.days_send = this.newPush.days_send;
      }

      if (this.newPush.Type == 3) {
        template.date = this.newPush.Date;
      }

      if (this.newPush.Type != 2) {
        template.delay = this.newPush.DelaySee;
      }

      return template;
    },
    sendPush() {
      var vm = this;

      let tags = this.choiseTags;
      let appIdsSend = this.getAppsForSendPush();
      let appIncludeType = this.getIncludeAppForSendPush();
      let pushContent = this.getContentToPush();
      let timeForEverydayPush = this.getTimeForEverydayPush();
      let geoIncludeType = (this.newPush.geoExcept) ? 1 : 0;
      let geoCodes = this.getGeosForSendPush();

      if (geoCodes.length < 1) {
        this.errorModalText = 'Поле "ГЕО" не может быть пустым!';
        this.isErrorModal = true;
      }


      pushContent.forEach((push) => {
        let texts = push.lang;

        texts.forEach((text) => {
          if (text.text.length < 1 || text.title.length < 1) {
            this.errorModalText = 'Поле "Заголовок" и "Сообщение" не могут быть пустыми!';
            this.isErrorModal = true;
          }
        });
      });

      if (this.isErrorModal) {
        return;
      }

      let template = {
        app_id: appIdsSend,
        app_inc_type: appIncludeType,
        geo: geoCodes,
        geo_inc_type: geoIncludeType,
        offers: this.newPush.offers,
        statement: this.statementVal.value,
        except: this.exceptVal.value,
        type: this.newPush.type,
        day_of_the_week: timeForEverydayPush,
      }

      if (this.newPush.tags) {
        template.tags = this.getIds(this.newPush.tags);
      }

      this.addParamForTemplate(template);

      HTTP.put('/notify/edit', {
            notify_id: this.newPush.id,
            tags: tags,
            template: template,
            push: pushContent,
          })
          .then(function (response) {
            vm.$router.push({name:"finalEditStep"});
          })
          .catch(function (error) {
            vm.$router.push({name:"errorStep"});
            console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

          });
    },
  },
  mounted() {
    this.getTags();
    this.getOffers();
    this.getLanguages();
  },
  created() {
    this.getValuesForEdit();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="bootstrap/dist/css/bootstrap.css" scoped></style>
<style src="bootstrap-vue/dist/bootstrap-vue.css" scoped></style>

<style lang="scss" scoped>
.card-header-tabs li:last-child {
  display: none!important;
}
.hidden {
  display: none!important;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.repeat__day {
  padding: 8px;
}
.text {
  font-size: 14px;
  font-family: "HalvarR";
  color: #fafafa;
}
.nav-link {
  color: #ff2a00!important;
  font-size: 14px;
  font-family: "HalvarR";
}
.red {
  border: 2px solid #ff2a00!important;
}
@media only screen and (max-width: 767px) {
  .wrapper_modal__window .btns__block {
    padding: 0 25px 40px;
    height: 135px;
  }
}

</style>
<style lang="scss">
.modal__menu {
  padding: 48px 48px 30px 48px;
  background: #242424;
  border-radius: 10px;
  max-height: 570px !important;
  margin-right: 10px;

  &--item {
    font-family: HalvarR;
    color: #656565;
    border-radius: 24px;
    transition: .5s;
    padding: 15px 30px;
    position: relative;
    width: 250px;
    margin: 10px 0;
    cursor: pointer;

    &:hover {
      background: #333333;
    }

    img {
      position: absolute;
      right: 20px;
      top: 13px;
    }

    &.active-tab {
      background: #161616;
      color: #fff;
    }
  }
}
.card-header-tabs {
  display: flex;
  align-items: baseline;

  ::-webkit-scrollbar {
    width: 0px!important;
  }

  .nav-link.active {
    background: #ff2a00!important;
    border: none!important;;
  }

  .nav-link {
    white-space: nowrap;
    color: #f2f2f2!important;
    font-family: "HalvarR"!important;
    border-radius: 7px!important;
    margin-right: 5px;
    height: 42px;
    padding: 10px 15px;
    text-decoration: none!important;
  }
}

.btn-close__push {
  position: absolute;
  top: -4px;
  right: 10px;
  background: #ff2a00!important;
  font-family: "HalvarR"!important;
  font-size: 14px;
  height: 40px;
}


.card-header,
.card {
  border: none!important
}

.modal {
  &__intro {
    margin-top: -5px;
    width: 88%;
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #E2E2E2;
    opacity: 0.7;
  }

  &__second-step {
    .multiselect__select {
      right: 0 !important;
      top: 10px !important;
    }
  }

  &__label {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: rgba(226, 226, 226, 0.7);
    margin-bottom: 10px;
    margin-top: 25px;
    display: flex;

    &.tags {
      text-transform: capitalize;
    }

    &.title {
      flex-direction: inherit;
    }

    &.time {
      margin: 0!important;
    }

    &.max-width {
      width: 100%;
    }

    .input__length{
      margin-left: 10px;
      color: #ff2a00;
    }
  }

  &__input {
    border: 1px solid rgba(204, 206, 221, 0.2);
    border-radius: 8px;
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #E2E2E2;
    width: 100%;

    &--container {
      display: flex;
      align-items: center;
      height: max-content;

      @media only screen and (max-width: 767px) {
        width: 100%;
        justify-content: space-between;
      }

      &:first-child {
        margin-right: 20px;

        @media only screen and (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .modal__label {
        width: auto;
        margin-top: 17px;
        white-space: nowrap;

        @media only screen and (max-width: 767px) {
          width: 150px;
          margin-top: 17px;
          white-space: break-spaces;
        }
      }

      p.modal__label {
        margin-top: 10px;
      }

      .modal__input.day {
        width: 45px;
      }

      .custom-checkbox {
        width: 22px;
        height: 22px;
        margin-bottom: -12px;

        &> span::before {
          content: " ";
          display: inline-block;
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          flex-grow: 0;
          border: 1px solid #adb5bd;
          border-radius: 6px;
          margin-bottom: 10px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
          position: relative;
          top: -3px;
        }

      }
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__second-step {
    .tabs {
      width: 100%!important;
      background: #242424!important;

      .vmt__nav,
      .vmt__tab-item {
        background: #242424!important;
      }
      .vmt__nav__item {
        width: 100px;
      }
    }
  }
}

.top__left,
.top__right {
  width: 45%;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  .modal__label {
    margin-top: 17px;
    margin-bottom: 0px;
  }
}

.multiselect {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #E2E2E2;

  &__single {
    background: transparent;
  }

  &__tag {
    background: rgba(204, 206, 221, 0.08);
  }

  &__tag-icon{
    &:after {
      content: "";
      background: url(../../assets/images/delete_item.svg);
      width: 16px;
      height: 16px;
      position: absolute;
      background-size: cover;
      top: 3px;
      right: 3px;
    }
    &:hover {
      background: transparent;
    }
  }

  &__tags {
    border: 1px solid rgba(204, 206, 221, 0.2)!important;
    border-radius: 8px;
    background: transparent!important;
  }

  &__content-wrapper {
    background: #323232!important;
    border: 1px solid rgba(204, 206, 221, 0.1);
  }

  &__option--highlight {
    background: rgba(204, 206, 221, 0.2);;
    outline: none;
    color: #e2e2e2;

    &::after {
      display: none;
    }
  }
  &__option--selected.multiselect__option--highlight {
    background: #ff2a00;
  }
}

.add_var {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #161616;
  width: 30px;
  height: 30px;
  color: #fafafa;
  font-family: "HalvarR";
  opacity: 0.5;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 105px;
  right: 43px;
  z-index: 1;
}

.push__tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
  width: 95%;
  height:40px;

  .push__tab {
    font-family: "HalvarR";
    color: #fafafa;
    margin-right: 15px;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 10px;

    &.active {
      color: #ff2a00;
      background:#161616;
    }
  }
}

.vc-container.vc-is-dark {
  background-color: transparent;
  border: none;
}

.back-step__btn {
  background: rgba(204, 206, 221, 0.1);
  border-radius: 8px!important;
  padding: 12px 45px;
  font-family: "HalvarR"!important;
  font-style: normal;
  font-weight: 700;
  font-size: 15px!important;
  line-height: 17px!important;
  color: #E2E2E2;
  border: none;

  @media only screen and (min-width: 768px) {
    margin-right: 30px!important;
  }

  @media only screen and (max-width: 767px) {
    margin-right: 0px!important;
  }
}

.close__btn {
  font-family: "HalvarR";
  color: #e2e2e2;
  font-size: 20px;
  margin-left: auto;
  cursor: pointer;
}
.wrapper_modal__window {
  position: relative;
}
.wrapper_modal__window h1 {
  margin-top: 15px;
}
.required {
  border-radius: 8px;
}

.checkbox {
  &__module {
    display: flex;
    width: 90%;
    align-items: center;
    margin-bottom: 10px;
  }
  &__block {
    display: flex;
    flex-direction: column;

    label {
      font-family: "halvarR";
      font-size: 12px;
      color: rgba(226, 226, 226, 0.7);
    }

    input {
      padding: 0;
      margin: 0;
    }
  }
}

.checkbox {
  margin-bottom: 0.2em;
}

.custom-checkbox {
  width: 48px;
  position: relative;
  height: 48px;
  display: flex!important;
  align-items: center;
}

/* для элемента input c type="checkbox" */
.custom-checkbox>input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox>span {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox>span::before {
  content: ' ';
  display: inline-block;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox p {
  font-family: "HalvarR";
  margin: 0 0 10px 0;
  color: #fff;
  position: absolute;
  top: 14px;
  left: 12px;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox>input:not(:disabled):active+span::before {
  background-color: #b3d7ff;
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox>input:focus:not(:checked)+span::before {
  border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox>input:checked+span::before {
  border-color: #ff2a00;
  background-color: #ff2a00;
}

.card-header-tabs {
  margin-top: 7px;
  width: 70%;
  overflow-y: scroll;
  height: 55px;

  li {
    margin-top: 10px;
    &:last-child {
      position: absolute;
      right: 110px;
      top: -4px;
      background: #242424;
    }
  }
}
.tab-content {
  button {
    margin-top: 9px;
  }
}
.vc-is-dark {
  background-color: #242424!important;
  .vc-time-picker {
    background-color: #242424;

    .vc-date {
      display: none!important;
    }
  }

  .vc-pane-layout {
    font-family: 'HalvarR'!important;
    width: 310px!important;
  }

  select {
    background: #242424!important;
    border: 1px solid rgba(204, 206, 221, 0.2) !important;
    width: 60px!important;
    font-family: "HalvarR";
    margin-top: 0;
    height: 35px;
  }

  .vc-day-content {
    &:focus,
    &:active,
    &:hover {
      background-color: #ff2a00!important;
    }
  }
}

.time__inputs {
  &--block {
    margin-left: 30px;
    display: flex;
  }

  &--item {
    position: relative;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    .input__time {
      width: 90px;
      font-family: 'HalvarR';
      text-align: center;
      background-color: #353537;
      border: none!important;
    }

    .time__input--remove-btn {
      position: absolute;
      left: -10px;
      top: 10px;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background-color: #ff2a00;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff2a00;
      background: rgba(#ff2a00, 0.3);
      border: 2px solid #ff2a00;
      font-family: 'HalvarR';
    }

    .time__input--add-btn {
      position: absolute;
      right: -10px;
      top: 10px;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background-color: #11E000;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #11E000;
      font-family: 'HalvarR';
      background: rgba(#11E000, 0.3);
      border: 2px solid #11E000;
    }
  }
}

.repeat__day {
  .custom-checkbox > span::before {
    background: #000;
    border: none!important;
  }
}

.data__block {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: repeat(2, 310px);
  grid-template-rows: repeat(2, 1fr);

  &.fixed {
    margin-left: -66px;
    padding-left: 55px;
    background: #303030;
    margin-right: -65px;
    padding-bottom: 40px;
  }

  &:not(.fixed) {
    .grid__last-el {
      .modal__label {
        margin-top: 10px;
      }
    }
  }

  .grid__last-el {
    grid-column: 1/3;
  }
}

.lang-btn__block {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .add__lang-btn {
    padding: 12px 15px;
    font-family: HalvarR;
    font-size: 15px;
    color: rgba(226, 226, 226, 0.7);
    transition: .5s;
    width: max-content;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #000;
    }
  }
}
.not-everyday .card-header {
  display: none!important;
}
.valid-error {
  border: 2px solid #ff2a00;
}
.opacity-btn {
  opacity: 0.5;
  cursor: not-allowed!important;
}
.delay-see {
  padding: 10px 20px;
  margin-left: 20px;
}

.wrapper_modal.edit {
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 75px 0 0 0 !important;

    .modal {
      &__menu {
        display: flex;
        width: 90%;
        padding: 8px 10px 12px 10px;
        overflow-x: scroll;
        margin: 0 auto 5px;

        &--item {
          white-space: nowrap;
          display: flex;
          padding: 0 65px;
          height: 49px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .wrapper_modal__window {
      height: 100%;

      h1 {
        font-size: 20px !important;
      }

      .btns__block {
        height: auto;
        padding: 15px 25px 10px;
        margin-top: 10px;
      }

      .modal__input--container:first-child {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}

.edit {
  .wrapper_modal__window {
    overflow-y: scroll;
    overflow-x: unset;
    max-height: 575px;
  }
}
</style>