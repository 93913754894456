import axios from "axios";
export const HTTP = axios.create({
  baseURL:
    window.location.origin === "https://purnis.xyz"
      ? "https://backend.purnis.xyz/api/"
      : "https://backend.purnis.xyz/api/",
  headers: {
    Authorization: localStorage["token"],
  },
});
