<template>
  <div>
    <loader v-if="isLoading" />
    <main-header :login="true" />
    <div
      class="main-block"
      :class="{
        overflowHidden: copyModalPush === true || showSeeGeoModal === true || showSeeAppModal === true,
        overflowHidden1: stepPush || showSeeOffersModal || showSeeTagsModal || openModalDelete
      }"
    >
      <div class="home">
        <div class="top__block">
          <h1>Cписок пуш-уведомлений</h1>
        </div>
        <div class="filters filter-pushes">
          <multiselect
            v-model="searchDeviceValue"
            class="modal__select--single"
            :multiple="false"
            :options="deviceList"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            @input="devicePage(searchDeviceValue)"
          />
          <multiselect
            v-model="selectedNotifyType"
            class="modal__select--single multiselect"
            label="name"
            track-by="name"
            placeholder="Статус"
            :options="filterNotifyType"
            :multiple="false"
            :searchable="true"
            :allow-empty="true"
            @input="getPush"
          />
          <multiselect
            v-model="selectedStatus"
            class="modal__select--single multiselect"
            label="name"
            track-by="name"
            placeholder="Статус"
            :options="filterStatus"
            :multiple="false"
            :searchable="true"
            :allow-empty="true"
            @input="getPush"
          />
          <multiselect
            v-model="selectedApps"
            class="modal__select--single multiselect"
            label="name"
            track-by="name"
            placeholder="Приложения"
            :options="filterApps"
            :multiple="true"
            :searchable="true"
            :allow-empty="true"
            @input="getPush"
          />
          <multiselect
            v-model="selectedTags"
            class="modal__select--single multiselect"
            label="name"
            track-by="name"
            placeholder="Теги"
            :options="filterTags"
            :multiple="true"
            :searchable="true"
            :allow-empty="true"
            @input="getPush"
          />
          <multiselect
            v-model="selectedNotify"
            class="modal__select--single multiselect"
            label="name"
            track-by="name"
            placeholder="Пуши"
            :options="filterNotify"
            :multiple="true"
            :searchable="true"
            :allow-empty="true"
            @input="getPush"
          />
          <multiselect
            v-model="selectedCountry"
            class="modal__select--single multiselect"
            label="name"
            track-by="name"
            placeholder="Страна"
            :options="filterCountry"
            :multiple="true"
            :searchable="true"
            :allow-empty="true"
            @input="getPush"
          />
          <multiselect
            v-model="selectedLangs"
            class="modal__select--single multiselect"
            label="name"
            track-by="name"
            placeholder="Язык"
            :options="filterLangs"
            :multiple="true"
            :searchable="true"
            :allow-empty="true"
            @input="getPush"
          />
        </div>
        <div class="first-tab">
          <div class="wrapper">
            <div class="scroll_container">
              <div class="table__info">
                <div class="count__list">
                  <label class="modal__label">Показывать записей:</label>
                  <multiselect
                    v-model="value"
                    :options="listOptions"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="25"
                    class="count__multiselect"
                    @input="limitPage(value)"
                  />
                </div>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Статус</th>
                    <th>Название</th>
                    <th>Теги</th>
                    <th>Приложение</th>
                    <th>ГЕО</th>
                    <th>Офферы</th>
                    <th v-if="type !== 2">
                      Задержка
                    </th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in itemsAuto"
                    :key="item.id"
                  >
                    <td>{{ item.id }}</td>
                    <td
                      v-if="item.green"
                      style="color: #11e000"
                    >
                      Активно
                    </td>
                    <td
                      v-if="item.red"
                      style="color: #ff2a00"
                    >
                      В архиве
                    </td>
                    <td>{{ item.name }}</td>
                    <td>
                      <div
                        v-if="Object.keys(item.tags).length > 0"
                        class="all__geo"
                        @click="watchTags(item.tags)"
                      >
                        <img src="../assets/images/info.svg">
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="item.applications && item.applications.length > 1"
                        class="all__geo"
                        @click="watchApp(item.applications)"
                      >
                        <img src="../assets/images/info.svg">
                      </div>
                      <div v-else>
                        <span
                          v-for="itemApps in item.applications"
                          :key="itemApps.id"
                        >
                          {{ itemApps.name }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="item.geo.length > 3"
                        class="all__geo"
                        @click="watchGeo(item.geo)"
                      >
                        <img src="../assets/images/info.svg">
                      </div>
                      <div v-else>
                        <span
                          v-for="itemGeos in item.geo"
                          :key="itemGeos.id"
                        >
                          {{ itemGeos }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="item.offers.length > 1"
                        class="all__geo"
                        @click="watchOffers(item.offers)"
                      >
                        <img src="../assets/images/info.svg">
                      </div>
                      <div v-else>
                        <span
                          v-for="itemOffer in item.offers"
                          :key="itemOffer.id"
                        >
                          {{ itemOffer }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div v-if="item.type === 2">
                        0
                      </div>
                      <div v-if="item.type === 3">
                        {{ item.date }} {{ item.delay }}
                      </div>
                      <div v-if="item.type === 1">
                        <span v-if="item.delay">
                          {{ convertMinutes(item.delay) }}
                        </span>
                        <div v-if="item.push">
                          <div
                            v-if="item.push.length > 1"
                            class="all__geo"
                            @click="watchOffers(item.push)"
                          >
                            <img src="../assets/images/info.svg">
                          </div>
                          <span v-if="item.push.length == 1 && item.push[0].delay !== 0">
                            {{ convertMinutes(item.push[0].delay) }}
                          </span>
                          <span v-if="item.push.length == 1 && item.push[0].delay === 0" >
                            {{ 0 }}
                          </span>
                        </div>
                        <div v-else>
                          None
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                        class="see_btn"
                        @click="editPush(item)"
                      >
                        <div class="icon edit" />
                      </button>
                      <button
                        v-if="item.type !== 3"
                        class="delete_btn"
                        @click="copyPush(item)"
                      >
                        <div class="icon copy" />
                      </button>
                      <button
                        v-if="item.type !== 3"
                        class="delete_btn delete"
                        @click="stopPush(item)"
                      >
                        <img
                          v-if="item.green"
                          style="
                            width: 30px;
                            height: 40px;
                            opacity: 0.5;
                            position: relative;
                          "
                          src="../assets/images/pause.svg"
                        >
                        <img
                          v-if="item.red"
                          style="
                            width: 30px;
                            height: 40px;
                            opacity: 0.5;
                            position: relative;
                          "
                          src="../assets/images/play.svg"
                        >
                      </button>
                      <button
                        class="delete_btn delete"
                        @click="deletePushOpenModal(item)"
                      >
                        <img
                          src="../assets/images/delete_outline.svg"
                        >
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul
              v-if="showAutoPagination"
              class="pagination"
            >
              <li
                v-for="pag in pagAutoPush"
                :key="pag"
                :class="{ active: paginationPage_default === pag }"
                @click="paginationPage(pag)"
              >
                {{ pag }}
              </li>
            </ul>
          </div>
        </div>
        <step-push
          v-if="stepPush"
          :item-change="itemPush"
          :geos="geoList"
          :apps-list="appsList"
          :languages-list="languages"
          :tags-list="tagsList"
          @close="stepPush = !stepPush"
        />
        <see-geo
          v-if="showSeeGeoModal"
          :item="itemGeo"
          @close="showSeeGeoModal = !showSeeGeoModal"
        />
        <see-app
          v-if="showSeeAppModal"
          :item="itemApp"
          @close="showSeeAppModal = !showSeeAppModal"
        />
        <see-offers
          v-if="showSeeOffersModal"
          :item="itemOffers"
          @close="showSeeOffersModal = !showSeeOffersModal"
        />
        <see-tags
          v-if="showSeeTagsModal"
          :item="itemTags"
          @close="showSeeTagsModal = !showSeeTagsModal"
        />
        <div
          v-if="copyModalPush"
          class="modal__main"
        >
          <div class="wrapper_modal__window">
            <div class="top__block">
              <h1>Пуш скопирован и добавлен вверх списка</h1>
            </div>
            <div class="btns__block">
              <button
                class="next-step__btn"
                @click="updatePush"
              >
                Отлично
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="openModalDelete"
          class="modal__main"
        >
          <div class="wrapper_modal__window">
            <div class="top__block">
              <h1>Вы уверены что хотите удалить пуш безвозвратно?</h1>
            </div>
            <div
              class="btns__block"
              style="gap: 20px"
            >
              <button
                class="next-step__btn"
                @click="deletePush(itemDeleteId)"
              >
                Да
              </button>
              <button
                class="next-step__btn"
                style="background: transparent;border: 1px solid #161616"
                @click="openModalDelete = false"
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";
import stepPush from "@/components/pushPage/EditPush.vue";
import seeGeo from "@/components/pushPage/seeGeo.vue";
import seeApp from "@/components/pushPage/seeApp.vue";
import seeOffers from "@/components/pushPage/seeOffers.vue";
import seeTags from "@/components/pushPage/seeTags.vue";
import Multiselect from "vue-multiselect";
import loader from "@/components/elements/loader.vue";
import { HTTP } from "@/../axios-config";

export default {
  name: "PushPage",
  components: {
    // filtersMultiselect,
    Multiselect,
    MainHeader,
    stepPush,
    seeGeo,
    seeApp,
    seeOffers,
    seeTags,
    loader,
  },
  data() {
    return {
      itemsAuto: [],
      itemDeleteId: '',
      isLoading: true,
      value: "",
      listOption_default: 25,
      listOptions: ["25", "50", "100"],
      pagAutoPush: "",
      paginationPage_default: 1,
      showAutoPagination: false,
      stepPush: false,
      copyModalPush: false,
      showSeeGeoModal: false,
      showSeeAppModal: false,
      showSeeOffersModal: false,
      showSeeTagsModal: false,
      showErrorModal: false,
      errorArray: [],
      items: [],
      itemGeo: [],
      itemApp: [],
      itemTags: {},
      itemOffers: [],
      geoList: [],
      languages: [],
      appsList: [
        {
          name: "all",
          id: "all",
        },
      ],
      deviceList: [],
      searchDeviceValue: 'pwa',
      deviceType: 'pwa',
      openModalDelete: false,
      tagsList: [],
      type: 1,
      filterStatus: [],
      filterNotify: [],
      filterApps: [],
      filterTags: [],
      selectedNotify: [],
      selectedApps: [],
      selectedTags: [],
      selectedStatus: {
        name: 'Active',
        id: 0,
      },
      selectedNotifyType: {
        id: 1,
        name: 'Auto',
      },
      filterNotifyType: [],
      filterCountry: [],
      selectedCountry: [],
      filterLangs: [],
      selectedLangs: [],
    };
  },
  created() {
    this.getGeo();
    this.fetchTags();
    this.getLanguages();
    this.fetchPush()
  },
  methods: {
    fetchTags() {
      // HTTP.get(`/front/applications/tags/list/all`)
      //   .then(response => {
      //     let data = response.data.data;
      //     this.tagsList = data;
      //   })
      //   .catch((error) => {
      //     console.log('error', error);
      //   })
    },
    deletePushOpenModal(item) {
      this.itemDeleteId = item.id;
      this.openModalDelete = !this.openModalDelete;
      console.log('itemDeleteId', this.itemDeleteId);
    },
    devicePage(item) {
      this.deviceType = item;
      localStorage.setItem('deviceType', JSON.stringify(this.deviceType));
      this.fetchPush();
      this.getApps();
    },
    chooseAppsForGroups(data) {
      let appIds = data.appIds;
      if (appIds) {
        let groupNameArr = ["Dating", "Gambling", "Betting", "all"];

        for (let i = 0; i < appIds.length; i++) {
          if (groupNameArr.indexOf(appIds[i]) !== -1) {
            this.excludeApp = true;
            data.Application[i] = {
              Id: appIds[i],
              Name: appIds[i],
            };
          }
        }
      }
    },
    limitPage(limit) {
      this.listOption_default = limit;
      this.paginationPage_default = 1;
      this.getPush();
    },
    paginationPage(page) {
      this.paginationPage_default = page;
      this.getPush();
    },
    updatePush() {
      this.copyModalPush = false;
    },
    back() {
      this.$router.push({ name: "app" });
    },
    watchGeo(geoItems) {
      this.itemGeo = geoItems;
      this.showSeeGeoModal = true;
    },
    watchApp(appItems) {
      this.itemApp = appItems;
      this.showSeeAppModal = true;
    },
    watchOffers(offerItems) {
      this.itemOffers = offerItems;
      this.showSeeOffersModal = true;
    },
    watchTags(tagsItems) {
      this.itemTags = tagsItems;
      this.showSeeTagsModal = true;
    },
    getApps() {
      const vm = this;
      vm.appsList = [];

      HTTP.get(`/applications/all?type_device=${vm.deviceType}`)
        .then(function (response) {
          let data = response.data.data;
          for (let i = 0; i < data.length; i++) {
            vm.appsList.push(data[i]);
          }
          vm.fetchPush();
        })
        .catch(function (error) {
          console.log(error);
          vm.showErrorModal = true;
          vm.errorArray = error;

        });
    },
    convertMinutes(totalMinutes) {
      const minutesInDay = 24 * 60;
      const minutesInHour = 60;

      const days = Math.floor(totalMinutes / minutesInDay);
      const remainingMinutesAfterDays = totalMinutes % minutesInDay;
      const hours = Math.floor(remainingMinutesAfterDays / minutesInHour);
      const minutes = remainingMinutesAfterDays % minutesInHour;

      return `${days}d${hours}h${minutes}m`;
    },
    getPush() {
      let url = `notify/list?page=${this.paginationPage_default}&limit=${this.listOption_default}&filter[status]=${this.selectedStatus.id}`;

      const filters = {
        type_device: this.deviceType,
        type_notify: this.selectedNotifyType?.id,
        applications: this.selectedApps?.length ? this.getIds(this.selectedApps).join(',') : null,
        tags: this.selectedTags?.length ? this.getIds(this.selectedTags).join(',') : null,
        notify: this.selectedNotify?.length ? this.getIds(this.selectedNotify).join(',') : null,
        geo: this.selectedCountry?.length ? this.getCodeGeo(this.selectedCountry).join(',') : null,
        language: this.selectedLangs?.length ? this.getCodeGeo(this.selectedLangs).join(',') : null,
      };
      /* eslint-disable */
      url += Object.entries(filters)
        .filter(([_, value]) => value)
        .map(([key, value]) => `&filter[${key}]=${value}`)
        .join('');
      if ('filter'.includes(url)) {
        this.paginationPage_default = 1;
      }
      HTTP.get(url)
        .then((response) => {
          this.itemsAuto = [];
          this.items = response.data.content;
          let filters = response.data.filters;

          this.deviceList = filters.type_device;
          this.filterApps = filters.applications;
          this.filterTags = filters.tags;
          this.filterNotify = filters.notify;
          this.filterCountry = filters.geo;
          this.filterLangs = filters.language;

          this.filterNotifyType = Object.entries(filters.type_notify)
            .map(([key, value]) => ({
              name: key,
              id: value
            }));

          this.filterStatus = Object.entries(filters.status)
            .map(([key, value]) => ({
              name: key,
              id: value
            }));

          this.pagAutoPush = response.data.pages;
          this.showAutoPagination = (this.pagAutoPush > 1) ? true : false;

          console.log('items 1', this.items.length);

          for (let i = 0; i < this.items.length; i++) {
            switch (this.items[i].is_stashed) {
              case 1:
                this.items[i].red = true;
                this.items[i].isStashed = "В архиве";
                break;
              default:
                this.items[i].green = true;
                this.items[i].isStashed = "Активно";
                break;
            }

            if (this.items[i].push) {
              for (let j = 0; j < this.items[i].push.length; j++) {
                let pushes = this.items[i].push[j];

                pushes.delayFormat = this.convertMinutes(pushes.delay);
              }
            }

            switch (this.items[i].offers) {
              case "":
                this.items[i].offers = "All";
                break;
              default:
                break;
            }
            this.chooseAppsForGroups(this.items[i]);
            this.itemsAuto.push(this.items[i]);
          }
        })
        .catch(function (error) {
          console.log(error);

          //   if (error.response.status === 401) {
          //     localStorage.removeItem("token");
          //     vm.$router.push({ name: "Login" });
          //   }
          // vm.showErrorModal = true;
          // vm.endPoint = "/front/applications/notifytemplatetasks/1/";
          // vm.errorArray = error;

        });
    },
    fetchPush() {
      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        history.go(1);
      };

      this.getPush();
      this.isLoading = false;
    },
    stopPush(data) {
      const vm = this;
      let stash = data.isStashed === "В архиве" ? 0 : 1;
      if (data.type === 3) {
        stash = 1;
      }
      HTTP.put(`/notify/edit/status`, {
        "status": stash,
        "notify_id": data.id
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          vm.fetchPush();
        })
        .catch(function (error) {
          console.log(error)
        });
    },
    deletePush(id) {
      const vm = this;
      HTTP.delete(`notify/delete/${id}`)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          vm.openModalDelete = false;
          vm.fetchPush();
        })
        .catch(function (error) {
          console.log(error)
        });
    },
    editPush(data) {
      this.itemPush = data;
      this.itemPush.device = this.deviceType.ident;
      console.log('ssss', this.itemPush);
      let apps = this.appsList;
      let appsExclude = this.itemPush.app_except;
      this.itemPush.appIdExcept = [];
      for (let i = 0; i < appsExclude.length; i++) {
        this.itemPush.appIdExcept.push(
          apps.find((app) => app.id == appsExclude[i])
        );
      }
      this.stepPush = true;
    },
    getIds (arr) {
      return arr.map(app => app.id);
    },
    getCodeGeo (arr) {
      return arr.map(app => app.code);
    },
    copyPush(data) {
      let vm = this;
      let app_id = data.app_id;
      let pushItem = {};
      let choiseTags = data.tags;
      let pushes = [];

      let app_except = data.app_except ? data.app_except : [];

      let template = {
        app_inc_type: app_except,
        geo: data.geo,
        geo_inc_type: 0,
        offers: data.offers,
        statement: data.statement,
        except: data.except,
        type: data.type,
        delay: (data.type !== 2) ? data.delay : 0,
        day_of_the_week: data.day_of_the_week,
        local_time: data.local_time,
        type_device: this.deviceType,
      };

      if (app_id && app_id.length > 0) {
        template.app_id = app_id;
      }

      if (data.tags && data.tags.length > 0) {
        template.tags = this.getIds(data.tags);
      }

      if (data.type == 2) {
        template.days_send = data.days_send;
        template.infinity_send = data.infinity_send;
      }

      if (data.type == 3) {
        template.date = data.date;
      }

      for (let pushCount = 0; pushCount < data.push.length; pushCount++) {
        pushItem = {};

        pushItem.name = data.name;
        pushItem.ico_url = data.push[pushCount].ico_url;
        pushItem.pic_url = data.push[pushCount].pic_url;
        pushItem.url = data.push[pushCount].url;
        pushItem.lang = data.push[pushCount].lang;
        pushItem.delay =  data.push[pushCount].delay ? data.push[pushCount].delay : 0;
        pushes.push(pushItem);
      }
      HTTP.post("/notify/create", {
        template: template,
        push: pushes,
        tags: choiseTags,
      })
        .then(function () {
          vm.copyModalPush = true;
          vm.getPush();
        })
        .catch(function (error) {
          console.log(error);

          vm.showErrorModal = true;
          vm.endPoint = "/front/applications/notifytemplatetasks/ edit";
          vm.errorArray = error;

        });
    },
    getGeo() {
      const vm = this;

      HTTP.get("/geo/")
        .then(function (response) {
          let all = {
            name: "All",
            code: "all",
          };
          vm.geoList = response.data.data;
          vm.geoList.unshift(all);
        })
        .catch(function (error) {
          console.log(error.response.status);
          vm.showErrorModal = true;
          vm.endPoint = "/geo";
          vm.errorArray = error;

        });
    },
    getLanguages() {
      const vm = this;

      HTTP.get("/language/")
        .then(function (response) {
          vm.languages = response.data.data;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }
          vm.showErrorModal = true;
          vm.endPoint = "/front/applications/front/language";
          vm.errorArray = error;

        });
    },
  },
};
/* eslint-disable */
</script>

<style lang="scss" scoped>
.first-tab {
  height: auto;
}
.wrapper {
  height: 100%;
  max-height: 60vh;
}

button {
  min-width: 60px !important;
  height: 52px;

  @media only screen and (max-width: 767px) {
    height: 0;
  }
}

button:hover {
  transform: scale(0.8);
}
</style>
<style lang="scss">
.modal__main {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__main .wrapper_modal__window {
  text-align: center;
}

.main-block {
  width: 90vw;
  height: 100vh;
  display: block;
  align-items: flex-start;
  justify-content: center;
  padding: 120px 80px;

  @media only screen and (max-width: 767px) {
    padding: 85px 15px;
    overflow-y: scroll;
  }
}
h1 {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  display: flex;
  align-items: center;
  color: #e2e2e2;
  margin-top: -25px;
}
.btn-block {
  display: flex;
  justify-content: space-between;
  width: 50px;
  position: absolute;
  right: 32px;

  button {
    background: transparent;
    border: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    min-width: 60px;
  }
}
.app-page {
  width: 90vw;
}
.top__block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
}
thead {
  background: rgba(226, 226, 226, 0.1);
  height: 43px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #e2e2e2;
  height: 43px;

  tr {
    position: relative !important;
    &::after {
      content: " ";
      top: 40px;
      left: 0;
      width: 100%;
      position: absolute;
      border-radius: 8px;
      background: linear-gradient(to right, #ff6700, #ff2a00, #830be2, #0000ff);
      padding: 1px;
    }
    th {
      height: 43px;
    }
    th:first-child {
      border-radius: 10px 0 0 0;
    }
    th:last-child {
      border-radius: 0 10px 0 0;
    }
  }
}
tbody {
  text-align: center;
  tr {
    height: 60px;
    border-color: unset !important;
    border-style: unset !important;
    border-width: unset !important;
    transition: .2s;
    
    &:hover {
      background: #444444;
    }
    td {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: #e2e2e2;
      max-width: 320px;
      padding: 3px;
    }

    &:nth-child(even) {
      background: rgb(82, 82, 82, 0.24);
      &:hover {
        background: #444444;
      }
    }
  }
}
.item {
  border: 1px solid;
  width: max-content;
  padding: 10px 20px;
  min-width: 100px;
  margin-bottom: 10px;
}
.add_btn {
  padding: 10px 20px;
  margin-bottom: 10px;
}
.add-push__modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}
.add-push__modal-item {
  background: white;
  padding: 20px;
  text-align: center;
}
.wrapper {
  width: 100%;
  overflow-y: unset;
  margin-top: 15px;
  position: relative;
}

.push__counter {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #e2e2e2;
  margin-bottom: 10px;
  padding-right: 2px;
  text-align: right;
}

.delete_btn {
  border: none;
  background: transparent;
  color: #e2e2e2;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;

  img {
    width: 40px;

    @media only screen and (max-width: 767px) {
      top: -21px;
      position: relative;
    }
  }
}

.see_btn {
  border: none;
  background: transparent;
  color: #e2e2e2;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;
}
.back_btn {
  border: none;
  background: transparent;
  color: #e2e2e2;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;
}
.tabs {
  width: 90vw !important;
  height: unset !important;
  background: transparent !important;
}
.vmt__tab-item {
  background: transparent !important;
  width: 90vw !important;
}
.vmt__nav,
.tabs__content {
  background: #161616 !important;
}
.vmt__nav {
  display: block !important;
}
.vmt__nav__items .vmt__nav__item {
  width: 319px;
  color: #7c7c7c;
}
.vmt__nav__items .vmt__nav__item.active {
  color: #e2e2e2 !important;
}
.vmt__tab__slider {
  background: #ff2a00 !important;
}
.first_tab {
  display: flex;
  flex-wrap: wrap;
}
.icon {
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon.edit {
  background: url(../assets/images/edit.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  &:hover {
    transform: scale(0.8);
  }
}
.icon.copy {
  background: url(../assets/images/copy.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;

  &:hover {
    // background: url(../assets/images/copy_hover.svg);
    // background-repeat: no-repeat;
    // background-size: cover;
    transform: scale(0.8);
  }
}
.icon.delete {
  background: url(../assets/images/delete_outline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;

  &:hover {
    //   background: url(../assets/images/delete_outline-hover.svg);
    //   background-repeat: no-repeat;
    //   background-size: cover;
    transform: scale(0.8);
    fill: #ff2a00;
  }
}
.all__geo {
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: #830be2;
  }
}
#sort-bar {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  padding: 0 2px;

  @media only screen and (max-width: 767px) {
    display: block;
  }
  div {
    width: 23%;
    min-width: 297px;

    @media only screen and (max-width: 767px) {
      width: auto;
      min-width: auto;
    }
  }
  .multiselect__tags {
    margin-top: 18px;
    min-height: 39px !important;
  }
  .modal_select .multiselect__select {
    top: 10px !important;
    right: -127px !important;
  }
}

.two_inputs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div {
    min-width: 141px !important;

    .multiselect__select {
      top: 17px !important;
      right: -48px !important;
    }

    .multiselect__tags {
      min-width: 95%;
      margin-top: 8px !important;
    }
  }
}

.vld-icon {
  svg {
    stroke: #ff2a00 !important;
  }
}

.overflowHidden,
.overflowHidden1 {
  overflow: hidden;
  height: 100vh;
}
</style>
