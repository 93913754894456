import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Tabs from "vue-material-tabs";
import moment from "moment";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VCalendar from "v-calendar";
import Vuelidate from "vuelidate";
import { HTTP } from "../axios-config";
import { cookiesAction } from "../cookiesAction";
import * as Sentry from "@sentry/vue";


if (localStorage["token"]) {
  const vm = this;
  let date = new Date();
  let msDate = parseInt(localStorage["access_time"]) * 1000;
  let expDate = new Date(msDate);
  console.log('date now', date);
  console.log('date exp', expDate);
  console.log('ret', date > expDate);
  setInterval(function() {
    if (date >= expDate) {
      date = '';
      HTTP.post(`/refresh/`, {
        refresh_token: cookiesAction.getCookie("refresh_token"),
        access_token: localStorage["token"],
      })
        .then(function (response) {
          console.log('refresh token', response);
          localStorage.setItem("token", response.data.data.access_token);
          localStorage.setItem("access_time", response.data.data.access_token_expiration_datetime);
          cookiesAction.setCookie("refresh_token", response.data.data.refresh_token);
        })
        .catch(function (error) {
          console.log(error);
          localStorage.removeItem("token");
          vm.$router.push({ name: "Login" });
        });
    }
  }, 1000)
}

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YY HH:mm");
  }
});

Vue.filter("formatDateNoTime", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YY");
  }
});

Vue.use(VCalendar);
Vue.use(Vuelidate);
Vue.use(Tabs);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Use v-calendar & v-date-picker components

Vue.config.productionTip = false;
Vue.prototype.axios = axios;

Sentry.init({
  Vue,
  dsn: "https://71596dc73cd2dd4954fb7a73bfedac39@sentry.tsprjct.com/22",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
  ],
  tracesSampleRate: 1.0,
});


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
