<template>
  <div>
    <main-header :login="true" />
    <div class="main-block">
      <div class="home">
        <div class="app-page">
          <div>
            <div class="top__block">
              <h1>Приложения</h1>
              <button
                class="add-push__btn"
                @click="showAddApp = !showAddApp"
              >
                <img
                  src="../assets/images/plus.svg"
                  alt=""
                >
              </button>
            </div>
            <div class="filters filter-apps">
              <multiselect
                v-model="searchDeviceValue"
                class="modal__select--single"
                :multiple="false"
                track-by="name"
                label="name"
                :options="deviceList"
                :allow-empty="false"
                :show-labels="false"
                @input="devicePage(searchDeviceValue)"
              />
              <multiselect
                v-model="selectedStatus"
                class="modal__select--single multiselect"
                label="name"
                track-by="name"
                placeholder="Статус"
                :options="filterStatus"
                :multiple="false"
                :searchable="true"
                :allow-empty="true"
                @input="fetchApp"
              />
              <multiselect
                v-model="selectedApps"
                class="modal__select--single multiselect"
                label="name"
                track-by="name"
                placeholder="Приложения"
                :options="filterApps"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
                @input="fetchApp"
              />
              <multiselect
                v-model="selectedTags"
                class="modal__select--single multiselect"
                label="name"
                track-by="name"
                placeholder="Теги"
                :options="filterTags"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
                @input="fetchApp"
              />
              <multiselect
                v-model="selectedNotify"
                class="modal__select--single multiselect"
                label="name"
                track-by="name"
                placeholder="Пуши"
                :options="filterNotify"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
                @input="fetchApp"
              />
            </div>
            <div
              class="first-tab"
              style="display: flex; flex-wrap: wrap"
            >
              <div
                v-for="item in itemsActive"
                :key="item.id"
                class="item"
              >
                <div class="item__stats">
                  <div
                    :class="{'none-border': item.type_device === 'pwa'}"
                    class="item__stats--item"
                  >
                    <div class="item__stats--img">
                      ID
                    </div>
                    <div class="item__stats--id">
                      {{ item.id }}
                    </div>
                  </div>
                  <div
                    v-if="item.type_device !== 'pwa'"
                    class="item__stats--item"
                  >
                    <div class="item__stats--img">
                      <img
                        src="../assets/images/icon_users.svg"
                        alt=""
                      >
                    </div>
                    <div class="item__stats--id">
                      {{ item.count_active_users }}
                    </div>
                  </div>
                </div>
                <div class="item__top">
                  <img
                    v-if="item.image === 'ban'"
                    class="item__top--logo"
                    src="../assets/images/app-logo.png"
                    alt=""
                  >
                  <img
                    v-else
                    class="item__top--logo"
                    :src="item.image"
                    alt=""
                  >
                  <div class="item__top--title-block">
                    <p class="item__top--title-name">
                      {{ item.name }}
                    </p>
                    <p
                      class="item__top--title-group"
                      @click="openTagsModal(item)"
                    >
                      Редагування
                      <img
                        src="@/assets/images/edit.png"
                        alt="icon"
                      >
                    </p>
                    <p :class="'item__top--title-type ' + item.colorStatus">
                      {{ item.statusInGoogle }}
                    </p>
                  </div>
                  <button
                    v-if="item.is_stashed === 0"
                    class="delete-app__btn"
                    @click="deleteApp(item)"
                  >
                    <div class="remove__btn">
                      -
                    </div>
                  </button>
                  <button
                    v-else
                    class="delete-app__btn green"
                    @click="deleteApp(item)"
                  >
                    <div class="remove__btn">
                      +
                    </div>
                  </button>
                </div>
                <div
                  class="item__main--tags"
                  v-if="item.tags && item.tags.length > 0"
                >
                  <div class="item__main--tags-title">Tags</div>
                  <div class="item__main--tags-content">
                    <div class="item__main--tag" v-for="tag in item.tags" :key="tag.id">
                      {{ tag.name }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <ul
              v-if="showActivePagination === true"
              class="pagination"
            >
              <li
                v-for="pag in dataPages"
                :key="pag"
                :class="{ active: paginationPage_default === pag }"
                @click="paginationActivePage(pag)"
              >
                {{ pag }}
              </li>
            </ul>
          </div>
          <add-app
            v-if="showAddApp"
            :device="deviceType"
            :tags-list="tagsList"
            @close="closeAppModal"
          />
        </div>
      </div>
      <edit-app
        v-if="isOpenTagModal"
        :item="itemApp"
        :tags-list="tagsList"
        @update="updateApp"
        @close="isOpenTagModal = !isOpenTagModal"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import MainHeader from "@/components/header/header.vue";
import addApp from "@/components/appPage/addApp.vue";
import { HTTP } from "@/../axios-config";
import Multiselect from "vue-multiselect";
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
import EditApp from "@/components/appPage/editApp.vue";

export default {
  name: "appPage",
  components: {
    EditApp,
    filtersMultiselect,
    Multiselect,
    addApp,
    MainHeader,
  },
  data() {
    return {
      showAddApp: false,
      itemsActive: [],
      itemsArchive: [],
      deleteItems: [],
      temp: [],
      paginationPage_default: 1,
      showActivePagination: false,
      showArchivePagination: true,
      deviceList: [],
      isOverflowed: false,
      searchDeviceValue: {
        id: 1,
        name: 'pwa',
        ident: 'pwa'
      },
      tagsList: [],
      isOpenTagModal: false,
      itemApp: [],
      selectedStatus: {
        name: 'Enabled',
        id: 0,
      },
      filterStatus: [],
      filterNotify: [],
      filterApps: [],
      filterTags: [],
      selectedNotify: [],
      selectedApps: [],
      selectedTags: [],
      dataPages: 0,
    };
  },
  methods: {
    openTagsModal(item) {
      this.itemApp = item;
      this.isOpenTagModal = !this.isOpenTagModal;
    },
    updateApp(data) {
      HTTP.put('/applications/update', data)
        .then((response) => {
          this.fetchApp();
          this.isOpenTagModal = false;
        })
        .catch((error) => {
          console.log('error', error);
        })
    },
    closeAppModal() {
      this.showAddApp = !this.showAddApp;
      this.fetchApp();
    },
    fetchTagsList() {
      HTTP.get(`/tags/list/all`)
        .then(response => {
          let data = response;
          this.tagsList = data;
        })
        .catch((error) => {
          console.log('error', error);
        })
    },
    getDeviceType() {
      // HTTP.get(`/type/device/`)
      //   .then(response => {
      //     this.deviceList = response.data;
      //     console.log('device', this.deviceList)
      //   })
      //   .catch(err => {
      //     console.error(err);
      //   });
    },
    devicePage(item) {
      this.deviceType = item;
      this.fetchApp();
    },
    loadDevice() {
      let savedOption = JSON.parse(localStorage.getItem('deviceType'));
      let android = {
        id: 2,
        name: 'android',
        ident: 'android',
      };
      this.searchDeviceValue = android;
      this.deviceType = android;
      if (savedOption) {
        console.log('ss', savedOption);
        this.deviceType = { name:savedOption, ident: savedOption };
        this.searchDeviceValue = savedOption;
        this.fetchApp();
      }
      console.log('search', this.searchDeviceValue);
    },
    getIds (arr) {
      return arr.map(app => app.id);
    },
    fetchApp() {
      const vm = this;
      let url = `/applications/get?page=${this.paginationPage_default}&limit=9&filter[typeDevice]=${this.searchDeviceValue.ident}&filter[status]=${this.selectedStatus.id}`
      if (this.selectedTags && this.selectedTags.length > 0) {
        url += `&filter[tags]=${this.getIds(this.selectedTags).join(',')}`
      }
      if (this.selectedApps && this.selectedApps.length > 0) {
        url += `&filter[applications]=${this.getIds(this.selectedApps).join(',')}`
      }
      if (this.selectedNotify && this.selectedNotify.length > 0) {
        url += `&filter[notify]=${this.getIds(this.selectedNotify).join(',')}`
      }
      if ('filter'.includes(url)) {
        this.paginationPage_default = 1;
      }
      HTTP.get(url)
        .then((response) => {
          let data = response.data.data;
          console.log('data res', data)
          this.itemsActive = data;
          this.dataPages = response.data.pages;
          console.log('data res2', this.itemsActive)
          console.log('response!!!!', response.data.pages)
          this.showActivePagination = this.dataPages > 1 ?? true;
          console.log('response!!!!', this.showActivePagination)


          this.temp = data;
          vm.filterStatus = Object.entries(data.filters.status)
            .map(([key, value]) => ({
              name: key,
              id: value
            }));


          vm.filterNotify = (data.filters.notify) ? data.filters.notify : [];
          vm.filterApps = (data.filters.applications) ? data.filters.applications : [];
          vm.filterTags = (data.filters.tags) ? data.filters.tags : [];

          vm.deviceList = Object.entries(data.filters.typeDevice)
            .map(([key, value]) => ({
              name: value,
              id: key,
              ident: value,
            }));

          console.log('device', vm.deviceType);


          for (let i = 0; i < this.temp.length; i++) {
            this.temp[i].noImage = this.temp[i].image === 'ban'
                ? true
                : false;

            if (this.temp[i].is_stashed === 0) {
              this.temp[i].statusInGoogle = "Enable";
              this.temp[i].colorStatus = "green";
            } else {
              this.temp[i].statusInGoogle = "Disable";
              this.temp[i].colorStatus = "red";
            }

          }
          this.items = [];
        })
        .catch(function (error) {
          console.log(error);
            //
            // if (error.response.status === 401) {
            //   localStorage.removeItem("token");
            //   vm.$router.push({ name: "Login" });
            // }

        });
    },
    deleteApp(item) {
      const vm = this;
      let stash = (item.is_stashed === 1) ? 0 : 1;

      HTTP.delete(`/front/applications/${item.id}/${stash}`)
        .then((response) => {
          vm.fetchApp();
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    paginationActivePage(page) {
      this.paginationPage_default = page;
      this.fetchApp();
    },
  },
  created() {
    this.fetchTagsList();
    // this.getDeviceType();
    this.fetchApp();
    // this.loadDevice();
  },
  beforeMount() {

  }
};
</script>

<style scoped>
  .add-push__btn {
    margin-top: -45px;
  }
  .first-tab {
    overflow-y: scroll;
    justify-content: space-evenly;
    min-height: 220px;
    overflow-x: hidden;
  }

</style>

<style lang="scss">
.filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .multiselect {
    max-width: 19.3%;
    width: 100%;
  }
}
.app-page {
  @media only screen and (max-width: 767px) {
    height: fit-content;
    margin-top: 25px;
  }
  .border-bottom {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(204, 206, 221, 0.1);
  }
  .item {
    border: none !important;
    display: block;
    padding: 25px;
    position: relative;
    background: rgba(204, 206, 221, 0.08);
    border-radius: 10px;
    min-width: 340px;
    max-width: 340px;
    margin-top: 35px;
    margin-left: 20px;
    box-sizing: border-box;
    min-height: 200px;


    @media only screen and (max-width: 767px) {
      min-width: 74%;
      max-width: 74%;
    }

    &__stats {
      display: flex;
      font-family: HalvarR;
      width: calc(100% + 30px);
      justify-content: space-between;
      position: relative;
      top: -20px;
      margin-left: -20px;
      margin-bottom: -10px;

      &--item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &--img {
        width: 25px;
        height: 25px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #353538;
        margin-right: 10px;
        color: #fff;
        font-size: 12px;
        padding-left: 1px;
        box-sizing: border-box;
      }

      &--id,
      &--time {
        color: #e2e2e2;
        font-size: 12px;
      }
    }

    &__top {
      display: flex;

      &--logo {
        height: 48px;
        border-radius: 8px;
      }

      &--title-block {
        margin-top: -8px;
        margin-left: 15px;
      }

      &--title-name {
        font-family: "HalvarR";
        margin-top: 7px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
        color: #e2e2e2;
      }

      &--title-group {
        font-family: "HalvarR";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: rgba(226, 226, 226, 0.5);
        margin: -15px 0 5px;
        cursor: pointer;
        transition: .4s;

        img {
          width: 13px;
          position: relative;
          top: 2px;
        }

        &:hover {
          color: #ff2a00;
        }
      }

      &--title-type {
        font-family: "HalvarR";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: rgba(226, 226, 226, 0.5);
        margin-top: -2px;

        &.green {
          color: #11e000;
        }

        &.red {
          color: #ff2a00;
        }
      }

      .delete-app__btn {
        margin-left: auto;
        background: none;
        border: none;
        height: 29px;
        cursor: pointer;
        position: absolute;
        top: -8px;
        right: -14px;
      }
    }

    &__main {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      display: flex;
      align-items: center;
      border: 2px solid #353538;
      border-radius: 8px;
      padding: 15px 0px;
      margin-top: 10px;

      &--title {
        color: rgba(226, 226, 226, 0.5);
      }

      &--tags-content {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }

      &--tags-title {
        margin-bottom: 7px;
        font-size: 14px;
        width: 100%;
      }

      &--tags {
        font-family: HalvarR;
        color: white;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        width: 290px;
        overflow: hidden;
        padding: 8px 15px;
        box-sizing: border-box;
        border: 2px solid #353538;
        border-radius: 8px;
        max-height: 75px;
        min-height: 75px;
        transition: 0.2s ease-in-out;
        position: absolute;
        background-color: #242424;

        &:hover {
          max-height: 100vh;
        }
      }

      &--tag {
        white-space: nowrap;
        flex: 1 1 auto;
        min-width: 50px;
        box-sizing: border-box;
        text-align: left;
        cursor: pointer;
        
        &:not(:last-child) {
          position: relative;
          margin-right: 5px;
        }
      }

      .push_btn {
        width: 48%;
        height: 38px;
        background: rgba(204, 206, 221, 0.1);
        border-radius: 8px;
        border: none;
        color: #e2e2e2;
        font-family: 'HalvarR';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        cursor: pointer;
      }
      .app_btns {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .tabs {
    width: 90vw !important;
    background: transparent !important;
  }
  .vmt__tab-item {
    background: transparent !important;
  }
  .vmt__nav,
  .tabs__content {
    background: #161616 !important;
  }
  .vmt__nav {
    display: block !important;
  }
  .vmt__nav__items .vmt__nav__item {
    width: 319px;
    color: #7c7c7c;
  }
  .vmt__nav__items .vmt__nav__item.active {
    color: #e2e2e2 !important;
  }
  .vmt__tab__slider {
    background: #ff2a00 !important;
  }
}
.first_tab {
  display: flex;
  flex-wrap: wrap;
}
.add-push__btn {
  background: #0000FF;
  border: none;
  width: 38px;
  height: 38px;
  border-radius: 100px;
}

.restore__app {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: #11E000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #11E000;
  font-family: 'HalvarR';
  background: rgba(#11E000, 0.3);
  border: 2px solid #11E000;
  font-size: 20px;
}
.remove__btn {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff2a00;
  background: rgba(255, 42, 0, 0.3);
  border: 2px solid #ff2a00;
  font-family: "HalvarR";
  font-size: 30px;
  line-height: 20.5px;

  .green & {
    background: rgba(17, 224, 0, 0.3);
    border: 2px solid #11E000;
    font-size: 20px;
    color: #11E000;
    position: relative;
    padding-top: 2.5px;
  }
}

.type-device {
  width: 360px;
  margin-bottom: 20px;

  .multiselect__select {
    top: 11px !important;
    right: 0 !important;
  }
}

.filters {
  .multiselect__select {
    top: 11px !important;
    right: 0 !important;
  }

  .multiselect__placeholder {
    font-size: 16px;
  }
}

.pagination {
  flex-wrap: wrap;
}
</style>
